<template>
  <div class="listImg">
    <el-row :gutter="20">
      <el-col :span="8" v-for="(item, index) in listData" :key="index">
        <div class="listImgInner">
          <a target="_blank" :href="!item.is_link || item.is_link === 0 ? `${apiName}/detail?id=${item.id}` : item.link_url">
            <div class="listImgPic">
              <img :src="item.img" alt="">
            </div>
            <p class="listImgTxt single-line">
              {{item.title}}
            </p>
          </a>
        </div>
      </el-col>
    </el-row>
    <div class="listPage">
      <el-pagination
        background
        layout="prev, pager, next"
        :current-page="pageNum"
        :page-size="pageSize"
        :total="total"
        @current-change="currentChange">
      </el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    gropId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      pageSize: 12,
      pageNum: 1,
      total: 0,
      listDemo: [
        {
          title: '省旅投集团举办首期抖音短视频省旅投集团举办首期抖音短视频省旅投集团举办首期抖音短视频',
          img: require('../assets/img/home_vedio_img01.jpg'),
          id: '1',
        },
        {
          title: '集团公司领导赴焉支山景区调研',
          img: require('../assets/img/home_vedio_img02.jpg'),
          id: '2'
        },
        // {
        //   title: '集团公司领导赴焉支山景区调研',
        //   img: require('../assets/img/home_vedio_img02.jpg'),
        //   id: '2'
        // },
        // {
        //   title: '集团公司领导赴焉支山景区调研',
        //   img: require('../assets/img/ico_jt_y_a.png.png'),
        //   id: '2'
        // }
      ],
      listData: [
      ]
    }
  },
  filters: {
    //超过100位显示 ...
    ellipsis: function(value) {
      if (!value) return ''
      if (value.length > 120) {
        return value.slice(0, 120) + '...'
      }
      return value
    }
  },
  mounted() {
    var _this = this
    _this.getList()
  },
  methods: {
    async getList() {
      var _this = this;
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getNewsListImg', {group_id: _this.gropId, page: _this.pageNum, pageSize: _this.pageSize});
      _this.total = data.data.total
      _this.listData = data.data.list
    },
    currentChange(val) {
      var _this = this
      _this.pageNum = val
      _this.getList()
    }
  }
}
</script>